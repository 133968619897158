<template>
    <div id="UserSecuritySettings">
        <div class="col-12 py-5 my-5">
            <p>{{componentText.password}}</p>
            <b-button  @click="openPopup" class="cta-button px-5 py-3 my-4">{{componentText.reset}}</b-button>
        </div>
        <!--<div class="col-12 py-5 my-5">
            <p> {{componentText.view}}
                <a rel="noreferrer" target="_blank" href="/nutzungsbedingungen" v-if="$store.getters.skin === 13">Nutzungsbedingungen</a>
                <a rel="noreferrer" target="_blank" href="/terms-and-conditions" v-else>{{componentText.terms}}</a>
            </p>
            <p>{{componentText.view}}
                <a rel="noreferrer" target="_blank" href="/datenschutzrichtlinie" v-if="$store.getters.skin === 13">Datenschutzrichtlinie</a>
                <a rel="noreferrer" target="_blank" href="/privacy-policy" v-else>{{componentText.privacy}}</a>
            </p>
        </div>-->
        <div class="pop-up" v-if="showPopup">
            <div class="pop-up-background" @click="hidePopup"></div>
            <div class="pop-up-content bg-brand-secondary row">
                <div class="col-12">
                    <h4>{{componentText.reset}}</h4>
                </div>
                <div class="col-12 current-form" v-if="!showResetForm">
                    <b-form-group
                        id="input-group"
                        :label="componentText.currentPass"
                        label-for="currentPassword"
                    >
                        <b-form-input
                            type="password"
                            v-model="currentPassword"
                            id="currentPassword"
                            required
                            @keyup.enter.native ="checkCurrentPassword(currentPassword)"
                        ></b-form-input>
                        <p v-if="currentFailed">{{componentText.passFail}}</p>
                    </b-form-group>
                    <b-button @click="checkCurrentPassword(currentPassword)" class="cta-button px-5 py-3 mt-5">{{componentText.next}}</b-button>
                </div>
                <div class="col-12 reset-form" v-if="showResetForm && !passwordChanged">
                    <b-form-group
                        id="input-group-1"
                        :label="componentText.newPass"
                        label-for="newPassword"
                    >
                        <b-form-input
                            type="password"
                            v-model="$v.newPassword.$model"
                            :state="$v.newPassword.$dirty ? !$v.newPassword.$error : null"
                            id="newPassword"
                            required
                        ></b-form-input>
                        <b-form-invalid-feedback id="password-error" v-html="componentText.passLength">
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                        id="input-group-2"
                        :label="componentText.repeat"
                        label-for="repeatPassword"
                    >
                        <b-form-input
                            type="password"
                            v-model="$v.repeatPassword.$model"
                            :state="$v.repeatPassword.$dirty ? !$v.repeatPassword.$error : null"
                            id="repeatPassword"
                            required
                            @keyup.enter.native ="submitForm()"
                        ></b-form-input>
                        <b-form-invalid-feedback id="password-error">
                            {{componentText.noMatch}}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <p v-if="passwordMatch">{{componentText.passMatch}}</p>
                    <p v-if="errorMessage">{{componentText.error}}</p>
                    <b-button @click="submitForm()" class="cta-button px-5 py-3 mt-5">{{componentText.save}}</b-button>
                </div>
                <div class="col-12" v-if="passwordChanged">
                    <p>{{componentText.success}}</p>
                </div>
                <b-button class="close-popup" @click="hidePopup"><font-awesome-icon icon="times-circle"></font-awesome-icon></b-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";
import Popup from "@/components/Popup.vue";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";
const alphaNumAndSpecialValidator = helpers.regex("alphaNumAndSpecial", /^[a-z\d. ^£$%&*()}{@#~!?><>,|=_+¬-]*$/i);

export default {
    name: "UserSecuritySettings",
    mixins: [validationMixin],
    components: {
        Popup
    },
    validations: {
        newPassword: {
            required,
            minLength: minLength(10),
            alphaNumAndSpecialValidator
        },
        repeatPassword: {
            sameAsPassword: sameAs('newPassword')
        }
    },
    data() {
        return {
            loading: true,
            showPopup: false,
            currentPassword: '',
            newPassword: '',
            repeatPassword: '',
            currentFailed: false,
            showResetForm: false,
            passwordMatch: false,
            errorMessage: false,
            passwordChanged: false,
            authKey: ''
        };
    },
    mounted() {

    },
    methods: {
        openPopup(){
            return this.showPopup = true
        },
        hidePopup(){
            return this.showPopup = false
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        submitForm() {
            this.$v.$touch();
            if (this.$v.$anyError) {
                return;
            }else {
                this.resetPassword();
            }
        },
        async checkCurrentPassword(current){
            const payload = {
                user_id: this.$store.getters.user_id,
                password: current
            }
            let res = await axios.post(config.api_env + '/application/api-hc/check-password', payload, config.options)
            if(res.data.success && res.data.valid){
                this.showResetForm = true
                this.authKey = res.data.auth_key
            }else if (!res.data.valid){
                this.currentFailed = true
            }
        },
        async resetPassword(){
            const payload = {
                email: this.$store.getters.user.username,
                auth_key: this.authKey,
                password: this.newPassword,
                password_repeat: this.repeatPassword
            }
            let res = await axios.post(config.api_env + '/application/api-hc/reset-password', payload, config.options)
            if(res.data.success && res.data.password_changed){
                this.passwordChanged = true
            }else{
                this.errorMessage = true
            }
        }
    },
    computed: {
        /*privacy(){
            if(this.$store.getters.skin === 8){
                return "/img/boi/BOI-Wellbeing-Privacy-Policy.pdf"
            }else{
                return "/privacy-policy"
            }
        },
        terms(){
            if(this.$store.getters.skin === 8){
                return "/img/boi/BOI-Wellbeing-TCs.pdf"
            }else{
                return "/terms-and-conditions"
            }
        },*/
        componentText() {
            return this.getText.loggedIn.settings;
        },
    },
    watch: {},
};
</script>

<style lang="less">
#UserSecuritySettings {
    min-height: 350px;

}
</style>
